import { Storage } from '@ionic/storage';
import axios from "axios";
import {UserDataInterface} from "@/intrefaces/userDataInterface";

export const resumeApiUrl = process.env.VUE_APP_API_URL;
export const resumeWebUrl = process.env.VUE_APP_WEB_URL;
let userToken = '';
let tokenIsCorrect = false;
let userData: UserDataInterface;
let bFirstRun = true;
const onboardingRoute = '/onboarding';
export const defaultRoute = '/start';

export const getFirstRun = () =>
{
    return bFirstRun;
}

export const setFirstRun = (arg: boolean) =>
{
    bFirstRun = arg;
}

export const getOnboardingRoute = () =>
{
    return onboardingRoute;
}

export const getNewActivityId = () => {
    let id = 1;
    let i;
    for (i = 0; i < userData.activities.length; i++)
    {
        if (userData.activities[i].id > id)
        {
            id = userData.activities[i].id;
        }
    }
    return id + 1;
}

export const getNewActivityOrder = () => {
    let order = 1;
    let i;
    for (i = 0; i < userData.activities.length; i++)
    {
        if (userData.activities[i].order > order)
        {
            order = userData.activities[i].order;
        }
    }
    return order + 1;
}

export const getNewQualificationId = () => {
    let id = 1;
    let i;
    for (i = 0; i < userData.qualifications.length; i++)
    {
        if (userData.qualifications[i].id > id)
        {
            id = userData.qualifications[i].id;
        }
    }
    return id + 1;
}

export const getNewQualificationOrder = () => {
    let order = 1;
    let i;
    for (i = 0; i < userData.qualifications.length; i++)
    {
        if (userData.qualifications[i].order > order)
        {
            order = userData.qualifications[i].order;
        }
    }
    return order + 1;
}

export const getNewEducationId = () => {
    let id = 1;
    let i;
    for (i = 0; i < userData.educations.length; i++)
    {
        if (userData.educations[i].id > id)
        {
            id = userData.educations[i].id;
        }
    }
    return id + 1;
}

export const getNewEducationOrder = () => {
    let order = 1;
    let i;
    for (i = 0; i < userData.educations.length; i++)
    {
        if (userData.educations[i].order > order)
        {
            order = userData.educations[i].order;
        }
    }
    return order + 1;
}

export const getNewWorkExperienceId = () => {
    let id = 1;
    let i;
    for (i = 0; i < userData.experiences.length; i++)
    {
        if (userData.experiences[i].id > id)
        {
            id = userData.experiences[i].id;
        }
    }
    return id + 1;
}

export const getNewWorkExperienceOrder = () => {
    let order = 1;
    let i;
    for (i = 0; i < userData.experiences.length; i++)
    {
        if (userData.experiences[i].order > order)
        {
            order = userData.experiences[i].order;
        }
    }
    return order + 1;
}

export const getNewReferenceId = () => {
    let id = 1;
    let i;
    for (i = 0; i < userData.references.length; i++)
    {
        if (userData.references[i].id > id)
        {
            id = userData.references[i].id;
        }
    }
    return id + 1;
}

export const getNewReferenceOrder = () => {
    let order = 1;
    let i;
    for (i = 0; i < userData.references.length; i++)
    {
        if (userData.references[i].order > order)
        {
            order = userData.references[i].order;
        }
    }
    return order + 1;
}

export const validateEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}

export const checkVal = (arg: any) =>
{
    if (!arg)
    {
        return false;
    }
    return arg.toString().length >= 1;

}

export const getUserToken = async () => {
    if (userToken && userToken.length > 0)
    {
        return userToken;
    }
    const store = new Storage();
    await store.create();
    const t = await store.get('token');
    if (t)
    {
        userToken = t;
        return userToken;
    }
    return null;
}

export const setUserToken = async (token:string) => {
    userToken = token;
    const store = new Storage();
    await store.create();
    await store.set('token', userToken);
}

export const loadUserData = async () => {
    return new Promise((resolve, reject) => {
        const url = resumeApiUrl + 'get_all_data?token=' + userToken;
        axios.get(url).then(response => {
            tokenIsCorrect = true;
            userData = response.data.data;
            if (userData.address === 'null' || userData.address === null)
            {
                userData.address = '';
            }
            if (userData.objective === 'null' || userData.objective === null)
            {
                userData.objective = '';
            }
            if (userData.experiences.length > 0)
            {
                let i;
                for (i = 0; i < userData.experiences.length; i++)
                {
                    if (userData.experiences[i].work_period_start)
                    {
                        if (userData.experiences[i].work_period_start.indexOf('T') > 0)
                        {
                            userData.experiences[i].work_period_start = userData.experiences[i].work_period_start.substring(0, userData.experiences[i].work_period_start.indexOf('T'));
                        }
                    }
                    if (userData.experiences[i].work_period_end)
                    {
                        if (userData.experiences[i].work_period_end.indexOf('T') > 0)
                        {
                            userData.experiences[i].work_period_end = userData.experiences[i].work_period_end.substring(0, userData.experiences[i].work_period_end.indexOf('T'));
                        }
                    }
                }
            }
            resolve(userData);
        }).catch(error => reject(error));
    });
}

export const getUserData = () => {
    return userData;
}

export const userDataLoaded = () => {
    return (userData !== undefined);
}

export const isUserLoggedIn = async () => {
    await getUserToken();
    return userToken && tokenIsCorrect;
}
