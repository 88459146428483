
import { IonApp, IonContent, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote, IonRouterOutlet, IonSplitPane } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { getUserData } from './services/data';

export default defineComponent({
  name: 'App',
  data() {
    return {
      user_name: '',
      user_email: '',
    }
  },
  components: {
    IonApp,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonRouterOutlet,
    IonSplitPane,
  },
  methods: {
    getUserFullName() {
      let userData = getUserData();
      if (userData)
      {
        return `${userData.first_name} ${userData.last_name}`;
      }
      return '';
    },

    getUserEmail()
    {
      let userData = getUserData();
      if (userData)
      {
        return userData.email;
      }
      return '';
    },

    checkUserData()
    {
      this.user_email = this.getUserEmail();
      this.user_name = this.getUserFullName();
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const obj = this;
      this.appPages.forEach((el, index) => {
        if (obj.$route.fullPath.indexOf(el.url) !== -1)
        {
          obj.selectedIndex = index;
        }
      });
    }
  },
  mounted()
  {
    setInterval(this.checkUserData, 500);

    // set selected index
    const route = useRoute();
    this.appPages.forEach((el, index) => {
      if (el.url === route.path)
      {
        this.selectedIndex = index;
      }
    });
  },
  setup() {
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: 'Basic information',
        url: '/basic',
      },
      {
        title: 'Objective',
        url: '/objective',
      },
      {
        title: 'Key qualifications',
        url: '/qualifications',
      },
      {
        title: 'Work experience',
        url: '/experience',
      },
      {
        title: 'Education',
        url: '/education',
      },
      {
        title: 'Other activities',
        url: '/activities',
      },
      {
        title: 'References',
        url: '/references',
      },
      {
        title: 'Share your resume',
        url: '/share',
      },
      {
        title: 'Logout',
        url: '/logout',
      }
    ];

    const route = useRoute();

    return {
      selectedIndex,
      appPages,
      isSelected: (url: string) => url === route.path ? 'selected' : ''
    }
  }
});
