import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/basic'
  },
  {
    path: '/onboarding',
    component: () => import ('../views/Onboarding.vue'),
    meta: { hideMenu: true }
  },
  {
    path: '/start',
    component: () => import ('../views/LetsStart.vue'),
    meta: { hideMenu: true }
  },
  {
    path: '/login',
    component: () => import ('../views/Login.vue'),
    meta: { hideMenu: true }
  },
  {
    path: '/register',
    component: () => import ('../views/Register.vue'),
    meta: { hideMenu: true }
  },
  {
    path: '/basic',
    component: () => import ('../views/Basic.vue')
  },
  {
    path: '/objective',
    component: () => import ('../views/Objective.vue')
  },
  {
    path: '/activities',
    component: () => import ('../views/Activities.vue')
  },
  {
    path: '/qualifications',
    component: () => import ('../views/Qualifications.vue')
  },
  {
    path: '/education',
    component: () => import ('../views/Education.vue')
  },
  {
    path: '/logout',
    component: () => import ('../views/Logout.vue')
  },
  {
    path: '/experience',
    component: () => import ('../views/Experience.vue')
  },
  {
    path: '/references',
    component: () => import ('../views/References.vue')
  },
  {
    path: '/share',
    component: () => import ('../views/Share.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
